import React from "react";
import Layout from "../../components/layout";
 import LoginForm from "../join/loginForm";

const LoginPage = () => {
  return (
    <Layout>
   
      <div className="join-page">
        <LoginForm />
      </div>
    </Layout>
  );
};

export default LoginPage;
